import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header/Header"
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import Modal from '../components/modal/Modal'
import HomeModal from '../components/modal/HomeModal'

import HeroImage from '../components/2.2_guidelines/HeroImage'
import Main from '../components/2.2_guidelines/Main'
import SEO from "../components/seo"

import Loader from "../components/loader"


export default class GRecomandations extends Component {

  constructor(props) {
    super(props)
    this.state = {
       isMobile: false,
       modal:  null,
       href: null
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }

  handleClick(e) {
    // console.log('clicked');
  }

  goModal = (modal, href) => {
    this.setState(() => ({
     modal: modal,
     href: href
   }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null, href: null }))
  }

  render() {
    return (
      <>
      <Loader>
      <SEO
        title="VASCEPA Guidelines"
        keywords={[""]}
        description="Leading medical societies like the American Diabetes Association, the National Lipid Association, and the American Heart Association recognize VASCEPA (icosapent ethyl [IPE]) as an important CV treatment option. Learn about their guidance for VASCEPA."/>
        <HomeModal/>

        <Modal modal={this.state.modal} href={this.state.href} modalClosed={this.modalClosed}/>
        <Header isMobile={this.state.isMobile} hideNav={this.state.hideNav} goModal={this.goModal}/>
        <div className="grad"></div>
        <HeroImage onClick={this.handleClick} bckgrdImage={'header2-2'}/>
        <div className="wrapper">
            <Main isMobile={this.state.isMobile} goModal={this.goModal}/>
        </div>
        <ISI scrollPos={this.state.unstick} disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
          <div className="ref-outer">
        <div className="footnotes-container">
          <div className="footnotes">
            <p className="cv-footnote">
            ASCVD=atherosclerotic cardiovascular disease; CAD=coronary artery disease; CV=cardiovascular; LDL-C=low-density lipoprotein cholesterol; T2DM=type 2 diabetes mellitus; TG=triglyceride.
            </p>

          </div>
        </div>
        <div className="footnotes-container">
          <div className='references'>
              <p className="cv-footnote">
                <strong>References: 1. </strong>Virani SS, Morris PB, Agarwala A, et al. 2021 ACC expert consensus decision pathway on the management of ASCVD risk reduction in patients with persistent hypertriglyceridemia: a report of the American College of Cardiology Solution Set Oversight Committee. <em>J Am Coll Cardiol</em>. 2021;78(9):960-993.
                <strong> 2. </strong>Arnold SV, Bhatt DL, Barsness GW, et al. Clinical Management of Stable Coronary Artery Disease in Patients With Type 2 Diabetes Mellitus: A Scientific Statement From the American Heart Association. <em>Circulation</em>. 2020;141(19):e779-e806. 
                <strong> 3. </strong>Garber AJ, Handelsman Y, Grunberger G, et al. Consensus Statement by the American Association of Clinical Endocrinologists and American College of Endocrinology on the Comprehensive Type 2 Diabetes Management Algorithm – 2020 Executive Summary. <em>Endocr Pract</em>. 2020:26(1):107-139. 
                <strong> 4. </strong>American Diabetes Association. 10. Cardiovascular Disease and Risk Management: Standards of Medical Care in Diabetes—2021. <em>Diabetes Car</em>e. 2021;44(suppl 1):S125-S150.
                <strong> 5. </strong>Orringer CE, Jacobson TA, Maki KC. National Lipid Association Scientific Statement on the use of icosapent ethyl in statin-treated patients with elevated triglycerides and high or very-high ASCVD risk. <em>J Clin Lipidol</em>. 2019;13(6):860-872.  
                <strong> 6. </strong>Mach F, Baigent C, Catapano AL, et al. 2019 ESC/EAS Guidelines for the management of dyslipidaemias: lipid modification to reduce cardiovascular risk: The Task Force for the management of dyslipidaemias of the European Society of Cardiology (ESC) and European Atherosclerosis Society (EAS). <em>Eur Heart J.</em> 2020;41(1):111-188.
              </p>
          </div>
        </div>
          </div>
        <Footer />
        </Loader>
      </>
    )
  }
}
