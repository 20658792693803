import React, { Component } from "react"
import { Link } from "gatsby"
import Collapsible from "../collapsible/Collapsible"
import "./styles.css"

export default props => {

  const supStyle = {
    fontWeight: 100
  };

  function goModal(evt) {
    const dataAtt = evt.target.dataset.modal;
    const dataHref = evt.target.dataset.href || null;
    props.goModal(dataAtt, dataHref);
  };

  return (
    <main className="center-grid mobile-m-b-20">
      <section id="ACC" className="endpoints-guidelines">
        <div className="div-flex div-flex-center">
          <div className="copy">
            <p className="guidelines-headline">
              American College of Cardiology (ACC) Consensus Statement 2021:
            </p>
            <p className="body-copy">
              For patients with clinical ASCVD and LDL-C <span class="nowrap">&lt;70 mg/dL</span> and with
              persistent fasting triglycerides &ge;150 and &lt;5<span class="nowrap">00 mg/dL</span> who are
              on maximally tolerated statin therapy, the clinician should
              readdress lifestyle and medication adherence and reconsider
              possible secondary causes of hypertriglyceridemia. In the absence
              of these factors, it may be reasonable to add IPE as the next
              step.<sup>1</sup>
            </p>
            <p className="body-copy">
              The addition of IPE for those with clinical ASCVD is supported not
              only by the achievement of the primary and secondary endpoints in
              the total study population of REDUCE-IT<sup>&reg;</sup>, but by
              the results reported in the prespecified secondary prevention
              subgroup.<sup>1</sup>
            </p>
          </div>
          <div>
            <button
              id="ACCtag"
              data-modal="leavingsite-modal"
              data-href="https://www.jacc.org/doi/pdf/10.1016/j.jacc.2021.06.011"
              onClick={goModal}
              className="homeBtn mobile-align-center"
            >
              Learn more
            </button>
          </div>
        </div>
      </section>
      <hr />
      <section id="AHA" className="endpoints-guidelines">
        <div className="div-flex div-flex-center">
          <div className="copy">
            <p className="guidelines-headline">
              American Heart Association (AHA) Science Advisory:
            </p>
            <p className="body-copy">
              Released an advisory statement identifying icosapent ethyl as the
              first non&ndash;LDL-focused lipid therapy to demonstrate CV
              benefit. IPE was further recommended to be considered first-line
              therapy for patients with T2DM and CAD whose triglycerides remain
              elevated (>135 mg/dL) despite maximally tolerated statin and
              lifestyle changes.<sup>2</sup>
            </p>
          </div>
          <div>
            <button
              id="AHAtag"
              data-modal="leavingsite-modal"
              data-href="https://www.ahajournals.org/doi/pdf/10.1161/CIR.0000000000000766"
              onClick={goModal}
              className="homeBtn mobile-align-center"
            >
              Learn more
            </button>
          </div>
        </div>
      </section>
      <hr />
      <section id="AACE" className="endpoints-guidelines">
        <div className="div-flex div-flex-center">
          <div className="copy">
            <p className="guidelines-headline">
              American Association of Clinical Endocrinologists (AACE) Consensus
              Statement:
            </p>
            <p className="body-copy">
              Recommends to add icosapent ethyl 4 g/day if high ASCVD risk on
              maximally tolerated statins and{" "}
              <span className="nowrap">TG 135-499 mg/dL.</span>
              <sup>3</sup>
            </p>
          </div>
          <button
            id="AACEtag"
            data-modal="leavingsite-modal"
            data-href="https://pro.aace.com/disease-state-resources/diabetes/clinical-practice-guidelines-treatment-algorithms/comprehensive"
            onClick={goModal}
            className="homeBtn mobile-align-center"
          >
            Learn more
          </button>
        </div>
      </section>
      <hr />
      <section id="ADA" className="endpoints-guidelines">
        <div className="div-flex div-flex-center">
          <div className="copy">
            <p className="guidelines-headline">
              American Diabetes Association<sup>&reg;</sup> (ADA){" "}
              <em>Standards of Medical Care in Diabetes</em> for 2021:
            </p>
            <p className="body-copy">
              <strong>
                ADA gives icosapent ethyl level “A” recommendation
              </strong>{" "}
              for patients with diabetes and ASCVD or other CV risk factors on a
              statin with controlled LDL-C and elevated TG (135-499 mg/dL).
              <sup>4</sup>
            </p>

            <ul className="content-list">
              <li className="primary-list-item">
                "It should be noted that data are lacking with other omega‑3
                fatty acids and{" "}
                <strong>
                  results of the REDUCE-IT trial should not be extrapolated to
                  other products
                </strong>
                "
              </li>
            </ul>
          </div>
          <div>
            <button
              id="ADAtag"
              data-modal="leavingsite-modal"
              data-href="https://investor.amarincorp.com/news-releases/news-release-details/new-updates-american-diabetes-associationsr-2019-standards"
              onClick={goModal}
              className="homeBtn mobile-align-center"
            >
              Learn more
            </button>
          </div>
        </div>
      </section>
      <hr />

      <section id="NLA" className="endpoints-guidelines">
        <div className="div-flex div-flex-center">
          <div className="copy">
            <p className="guidelines-headline">
              National Lipid Association (NLA) Scientific Statement:
            </p>
            <p className="body-copy">
              Class I, Level B-R (STRONG) recommendation for the CV
              risk-lowering effects of icosapent ethyl for high-risk and
              very-high-risk patients with TG 135-499 mg/dL on statin therapy.
              <sup>5</sup>
            </p>
          </div>
          <div>
            <button
              id="NLAtag"
              data-modal="leavingsite-modal"
              data-href="https://investor.amarincorp.com/news-releases/news-release-details/national-lipid-associations-new-position-statement-use-icosapent"
              onClick={goModal}
              className="homeBtn mobile-align-center"
            >
              Learn more
            </button>
          </div>
        </div>
      </section>

      <hr />

      <section id="ESC_EAS" className="endpoints-guidelines">
        <div className="div-flex div-flex-center">
          <div className="copy">
            <p className="guidelines-headline">
              <span>
                European Society of Cardiology/European Atherosclerosis Society
                (ESC/EAS) Guidelines:
              </span>
            </p>
            <p className="body-copy">
              Recommend that icosapent ethyl, 2g twice a day, should be
              considered for patients with CV disease who have TG levels 135
              mg/dL to 499 mg/dL despite statin treatment.<sup>6</sup>
            </p>
          </div>
          <div>
            <button
              id="ESC_EAStag"
              data-modal="leavingsite-modal"
              data-href="https://investor.amarincorp.com/news-releases/news-release-details/new-2019-updates-european-society-cardiologys-and-european"
              onClick={goModal}
              className="homeBtn mobile-align-center"
            >
              Learn more
            </button>
          </div>
        </div>
      </section>
      <hr />

      <section className="endpoints-guidelines">
        <div className="endpoints-callouts">
          <div className="grad-border m-r-10">
            <p className="callout-copy">
              Leading medical societies recognize that clinical results with IPE
              should not be generalized
              to any other product
            </p>
          </div>
          <div className="cta-container m-center fit-content">
            <h3 className="cta-copy">
              VASCEPA may be affordable for most patients
            </h3>
            <Link className="cta-btn" to="/get-vascepa-now/">
              {" "}
              See how
            </Link>
          </div>
          <article className="disclosures-outlines">
            <p className="body-copy">
              10% of patients in the REDUCE-IT trial had TG levels less than 150
              mg/dL.
            </p>
            <p className="body-copy">
              Please refer to all society statements for further information and
              recommendations.
            </p>
          </article>
        </div>
      </section>

    </main>
  )
}
